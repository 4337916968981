var form, dialog;

$( document ).ready( function() {

  form = $("#frmLogin");

  dialog = $( "#login-form" ).dialog({
    autoOpen: false,
    resizable: false,
    height:300,
    width:280,
    position: {
      my: "center top+45",
      at: "top",
      collision: "flipfit"
    },
    modal: true,
    draggable: false,
    closeText: 'X',
    closeOnEscape: true,
    title: "Login to My Victory",
    buttons: {
      Cancel: function() {
        dialog.dialog( "close" );
      },
      "Login": login
    },
    open: function() {
      $('.ui-widget-overlay').bind('click', function() 
      {
        $("#login-form").dialog('close');
      })
    },
    close: function() {
      // form[ 0 ].reset();
    }
  });
});

$("#btnLogin").click(function() {

  $( "#login-form" ).dialog("open");

});


function login() {
  // validate
  
  // submit
  $.ajax({
    url: "/login",
    data: form.serialize(),
    dataType: "json",
    method: "POST",

  }).done(function(data) {
    
    if(data.errors) {
      $("#login-form").dialog('close');
      showError(data.errors);
    }
    else {
      $("#login-form").dialog('close');
      location.reload();
      return true;
    }
  })
  .fail(function(data) {
    showError("Could not connect to authentication server. Please try again.");
  });

  function showError(message) {
    var html = '<div class="alert alert-danger">' +
      '<ul>' +
        '<li>'+message+'</li>' +
      '</ul>' +
    '</div>';

    $(".notice-container").html(html);
  }

  
}

